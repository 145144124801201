import React from 'react';

const IconLogo = () => (
  <svg id="logo" width="84" height="96" viewBox="0 0 84 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42 3L3 25V70L42 93L81 71V26L42 3Z" stroke="#64FFDA" stroke-width="4"/>
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M41.16 62.28C39.2933 62.28 37.6133 61.96 36.12 61.32C34.6267 60.6533 33.4533 59.72 32.6 58.52C31.7467 57.32 31.32 55.92 31.32 54.32H36.2C36.3067 55.52 36.7733 56.5067 37.6 57.28C38.4533 58.0533 39.64 58.44 41.16 58.44C42.7333 58.44 43.96 58.0667 44.84 57.32C45.72 56.5467 46.16 55.56 46.16 54.36C46.16 53.4267 45.88 52.6667 45.32 52.08C44.7867 51.4933 44.1067 51.04 43.28 50.72C42.48 50.4 41.36 50.0533 39.92 49.68C38.1067 49.2 36.6267 48.72 35.48 48.24C34.36 47.7333 33.4 46.96 32.6 45.92C31.8 44.88 31.4 43.4933 31.4 41.76C31.4 40.16 31.8 38.76 32.6 37.56C33.4 36.36 34.52 35.44 35.96 34.8C37.4 34.16 39.0667 33.84 40.96 33.84C43.6533 33.84 45.8533 34.52 47.56 35.88C49.2933 37.2133 50.2533 39.0533 50.44 41.4H45.4C45.32 40.3867 44.84 39.52 43.96 38.8C43.08 38.08 41.92 37.72 40.48 37.72C39.1733 37.72 38.1067 38.0533 37.28 38.72C36.4533 39.3867 36.04 40.3467 36.04 41.6C36.04 42.4533 36.2933 43.16 36.8 43.72C37.3333 44.2533 38 44.68 38.8 45C39.6 45.32 40.6933 45.6667 42.08 46.04C43.92 46.5467 45.4133 47.0533 46.56 47.56C47.7333 48.0667 48.72 48.8533 49.52 49.92C50.3467 50.96 50.76 52.36 50.76 54.12C50.76 55.5333 50.3733 56.8667 49.6 58.12C48.8533 59.3733 47.7467 60.3867 46.28 61.16C44.84 61.9067 43.1333 62.28 41.16 62.28Z" 
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
